import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios';

Vue.config.productionTip = false
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

axios.interceptors.response.use(undefined, function (error) {
    if (error) {
        const originalRequest = error.config;
        if ((error.response.status === 401 || error.response.status === 400)
            && !originalRequest._retry
        ) {
            originalRequest._retry = true;
            store.dispatch('LogOut')
            return router.push('/login')
        }
    }
})

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
