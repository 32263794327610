<template>
    <template>
        <div>
            <router-view></router-view>
            <Navigation mode="admin" v-if="this.$route.name == 'admin'"></Navigation>
        </div>
    </template>
</template>

<script>
    import Navigation from '../navigation/navigation.vue'

    export default {
        components: {
            'Navigation': Navigation,
        }
    }
</script>
