<template>
    <div class="post-list" v-if="this.$route.params.tag && tags">
        <div class="block" v-for="post in tags">
            <router-link :to="post.Url">{{ post.Name }}</router-link>
            <p>{{ post.ShortDescription }}</p>
        </div>
        <Navigation mode="simple"></Navigation>
    </div>
</template>

<script>
    import axios from 'axios';
    import Navigation from '../navigation/navigation.vue'

    export default {
        components: {
            'Navigation': Navigation,
        },
        watch: {
            // call again the method if the route changes
            '$route': 'onRouteChange'
        },
        data() {
            return {
                tags: null
            }
        },
        created () {
            // fetch the data when the view is created and the data is
            // already being observed
            setTimeout(this.onRouteChange(), 0)
        },
        methods: {
            onRouteChange (to, from) {
                const self = this;
                if (this.$route.params.tag) {
                    axios
                        .get('/tags/' + this.$route.params.tag + '?t=' + (new Date).valueOf())
                        .then(response => {
                            self.tags =  response.data;
                        })
                }
            }
        }
    }

</script>
