<template>
        <div>
            <div class="blog-post">
                <router-link :to="{ name: 'progs', params: { id: 'new' }}">new</router-link>

                <p v-if="success" id="error">Successfully added/updated {{ updated_at }} </p>

                <div class="form-group">
                    <label name="id">ID</label>
                    {{ post.ID }}
                </div>

                <div class="form-group" style="display: flex">
                    <div class="form-group" style="width: 50%">
                        <label name="post_name">Name</label>
                        <input type="text" class="form-control" v-model="post.Name" id="post_name" required>
                    </div>
                    <div class="form-group" style="width: 50%">
                        <label name="post_alias">Alias</label>
                        <input type="text" class="form-control" v-model="post.Alias" id="post_alias" required>
                    </div>
                </div>

                <div class="form-group">
                    <label name="post_short_description">ShortDescription</label>
                    <input type="text" class="form-control" v-model="post.ShortDescription" id="post_short_description" required>
                </div>

                <div class="form-group">
                    <label name="post_name">Content</label>
                    <textarea v-model="post.Content"></textarea>
                </div>

                <div class="form-group">
                    <label>Tag</label>
                    <select name="tag" v-model="post.Tag">
                        <option v-for="tag in tags">{{tag}}</option>
                    </select>
                </div>

                <div class="form-group">
                    <label>Visible</label>
                    <select name="tag" v-model="post.Visible">
                        <option v-for="act in [{id: 0, value: 'hidden'},{id: 1, value: 'visible'}]"  :value="act.id" :selected="post.Visible === act.id" >{{ act.value }}</option>
                    </select>
                </div>
                <hr>
                <div class="form-group">
                    <button class="btn btn-primary" v-if="post.ID" v-on:click="update">Update</button>
                    <button class="btn btn-primary" v-if="!post.ID" v-on:click="add">Add</button>
                </div>
            </div>
            <Navigation mode="admin" :key="adminNavigationKey"></Navigation>
        </div>
</template>

<script>
    import axios from 'axios';
    axios.defaults.withCredentials = true;

    import Navigation from '../navigation/navigation.vue'
    import Upload from './upload.vue'

    const emptyPost = {
        Name: "",
        Content: "",
        ID: ""
    }

    export default {
        components: {
            'Navigation': Navigation,
            'Upload': Upload,
        },
        data() {
            return {
                post: emptyPost,
                updated_at: (new Date()).toLocaleString(),
                success: false,
                error: false,
                tags: 'android,docker,flash,git,handmade,html,javascript,linux,mysql,php,python,shell,web,windows'.split(','),
                adminNavigationKey: 0
            }
        },
        watch: {
            // call again the method if the route changes
            '$route': 'onRouteChange'
        },
        created () {
            // fetch the data when the view is created and the data is
            // already being observed
            setTimeout(this.onRouteChange, 0)
        },
        methods: {
            async onRouteChange (to, from) {
                if (this.$route.params.id && this.$route.params.id != 'new') {
                    const route = '/admin/progs/' + this.$route.params.id;
                    const response = await axios.get(route + '?t=' + (new Date).valueOf())
                    this.post = response.data
                    document.title = response.data.Title || 'admin drcreazy.com'
                    document.querySelector('meta[name="description"]').setAttribute("content", response.data.Description || 'admin drcreazy.com')
                } else {
                    this.post = emptyPost;
                }
            },
            async update() {
                const route = `/admin/progs/${this.post.ID}`;
                const response = await axios.patch(route, this.post)
                if (response.status === 200) {
                    this.success = true;
                    this.error = false;
                    this.rerenderNavigation()
                }
                this.success = false;
                this.error = true;
            },
            add() {
                const route = `/admin/progs`;
                axios
                    .post(route, this.post)
                    .then(response => {
                        if (response.status === 200) {
                            this.success = true;
                            this.error = false;
                            this.rerenderNavigation()
                            this.post = emptyPost;
                        }
                        this.success = false;
                        this.error = true;
                    })
            },
            rerenderNavigation() {
                this.adminNavigationKey++;
            }
        }
    }
</script>


<style>
    body {
        font-family: 'Roboto', sans-serif;
        font-size: 1.2em;
    }
    #wrapper {
        width: 1200px;
        margin: 0 auto;
    }
    pre {
        background-color: #ccc;
        padding: 10px;
        overflow: auto;
    }
    .block {
        padding:5px;
    }
    label {
        display: block;
        color: #ccc;
    }
    a {
        text-decoration: none;
        color: cadetblue;
    }
    textarea {
        width: 100%;
        height: 300px;
        font-size: 1.1em;
        border: 1px solid rgba(60,60,60,.26);
    }
    input, select {
        padding: 4px 0;
        background: none;
        border: 1px solid rgba(60,60,60,.26);
        width: 70%;
        font-size: 1.1em;
    }
</style>
