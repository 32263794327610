<template>
    <template>
        <div>
            <form @submit.prevent="submit">
                <div>
                    <label for="username">Username:</label>
                    <input type="text" name="username" v-model="form.username" />
                </div>
                <div>
                    <label for="password">Password:</label>
                    <input type="password" name="password" v-model="form.password" />
                </div>
                <button type="submit">Submit</button>
            </form>
            <p v-if="showError" id="error">Username or Password is incorrect</p>
        </div>
    </template>
</template>

<script>
    import {mapActions} from "vuex";

    export default {
        data() {
            return {
                form: {
                    username: "",
                    password: "",
                },
                showError: false
            }
        },
        methods: {
            ...mapActions(["LogIn"]),
            async submit() {
                const User = new FormData();
                User.append("username", this.form.username);
                User.append("password", this.form.password);
                try {
                    await this.LogIn(User);
                    this.$router.push("/admin");
                    this.showError = false
                } catch (error) {
                    this.showError = true
                }
            },
        }
    }
</script>
