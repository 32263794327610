import Vue from 'vue'
import VueRouter from 'vue-router'
import Tags from './components/tags/tags.vue'
import Art from './components/art/art.vue'
import App from './components/index/index.vue'
import Login from './components/login/login'
import Admin from './components/admin/admin'
import AdminProgs from './components/admin/admin-progs'
import Upload from './components/admin/upload'
import store from "./store";


Vue.use(VueRouter)

const router = new VueRouter({
    routes: [
        // dynamic segments start with a colon
        { path: '/',  name: 'index', component: App},
        { path: '/art/:id/:alias', component: Art},
        { path: '/tags/:tag', name: 'tags', component: Tags},
        { path: '/login', name: 'login', component: Login },
        { path: '/admin', name: 'admin', component: Admin,
            children: [
                { path: 'progs/:id', name: 'progs', component: AdminProgs },
                { path: 'upload', name: 'upload', component: Upload },
            ]
        },
    ],
})

function isAdminArea(to) {
    return ['admin', 'progs', 'upload'].indexOf(to.name) !== -1;
}

router.beforeEach((to, from, next) => {
    if (isAdminArea(to) && !store.getters.isAuthenticated){
        next({ name: 'login' })
    } else {
        next()
    }
})

export default router;
