<template>
    <nav v-if="latest">
        <hr/>
        <div class="block" v-for="post in latest" v-if="mode == 'admin'">
            <router-link :to="{ name: 'progs', params: { id: post.ID }}">{{ post.Name }}</router-link>
        </div>
        <div class="block" v-for="post in latest" v-if="mode == 'simple'">
            <router-link :to="post.Url">{{ post.Name }}</router-link>
        </div>
    </nav>
</template>

<script>
    import axios from 'axios';
    axios.defaults.withCredentials = true;

    export default {
        props: ['mode', 'key'],
        data() {
            return {
                latest: null
            }
        },
        async created () {
            const response = await axios.get(this.mode === 'admin' ? "/admin/progs" : "/latest")
            if (response.data) {
                this.latest = response.data;
            }
        }
    }
</script>
