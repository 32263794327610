<template>
    <div class="blog-post" v-if="this.$route.params.id && post">
        <h1>{{ post.Name }}</h1>
        <div v-html="post.Content"></div>
        <div v-if="this.$route.path !== '/'">
            more with <router-link :to="{ name: 'tags', params: { tag: post.Tag }}"> {{ post.Tag }}</router-link>
        </div>
        <Navigation mode="simple"></Navigation>
    </div>

</template>

<script>
    import axios from 'axios';
    import Navigation from '../navigation/navigation.vue'

    export default {
        data() {
            return {
                post: null
            }
        },
        components: {
            'Navigation': Navigation,
        },
        watch: {
            // call again the method if the route changes
            '$route': 'onRouteChange'
        },
        created () {
            // fetch the data when the view is created and the data is
            // already being observed
            setTimeout(this.onRouteChange, 0)
        },
        methods: {
            onRouteChange (to, from) {
                const self = this;
                if (this.$route.params.id) {
                    const route = '/art/' + this.$route.params.id + '/' + this.$route.params.alias;
                    axios
                        .get(route + '?t=' + (new Date).valueOf())
                        .then(response => {
                            self.post = response.data;
                            document.title = response.data.Title || 'drcreazy.com'
                            document.querySelector('meta[name="description"]').setAttribute("content", response.data.Description || 'drcreazy.com')
                        })
                }
            }
        }
    }
</script>
