<template>
    <div>
        <ul>
            <li v-for="file in files"><a :href="`/upload/${file.File}`">{{ file.File }}</a>
                <img v-if="file.Mime === 'image/png' || file.Mime === 'image/jpg'" :src="`/upload/${file.File}`" class="minipic" />
            </li>
        </ul>
    </div>
</template>

<script>
    import axios from 'axios';
    axios.defaults.withCredentials = true;

    export default {
        data() {
            return {
                files: null,
            }
        },
        async created() {
            const response = await axios.get('/admin/files');
            this.files = response.data;
        }
    }
</script>

<style>
    .minipic {
        width: 128px;
        max-height: 128px;
    }
</style>
