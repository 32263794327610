import axios from 'axios';

const state = {
    user: null
};

const getters = {
    isAuthenticated: state => !!state.user,
    StateUser: state => state.user,
};
const actions = {
    async LogIn({commit}, User) {
        const response = await axios.post('login', User)
        if (response.status === 200) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.token;

            const result = new Date();
            await commit('setUser', { name: 'admin', exp: result.setDate(result.getDate() + 3) })
        }
    },
    async LogOut({commit}) {
        commit('logOut')
    }
};
const mutations = {
    setUser(state, user) {
        state.user = user
    },
    logOut(state) {
        state.user = null
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};
