<template>
    <div>
        <div>
            <nav>
            <ul>
                <li><router-link :to="{ name: 'index' }">home</router-link></li>
                <li v-if="this.$store.getters.StateUser">
                    <ul>
                        <li><router-link :to="{ name: 'upload' }">upload</router-link></li>
                        <li><router-link :to="{ name: 'admin' }">{{ this.$store.getters.StateUser.name || '' }}</router-link></li>
                        <li><a class="cp" @click="logout">logout</a></li>
                    </ul>
                </li>
            </ul>
            </nav>
        </div>
        <hr/>
        <router-view/>
    </div>
</template>

<script>
    export default {
        methods: {
            async logout (){
                await this.$store.dispatch('LogOut')
                this.$router.push('/login')
            }
        },
    }
</script>

<style>
    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
        margin-top: 60px;
    }
    nav ul {
        padding: 0;
        margin:0;
    }
    nav ul li {
        margin: 0px 10px;
        display: inline-block;
    }

    .cp {
        cursor: pointer;
    }
</style>
