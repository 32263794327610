<template>
    <template>
        <div class="blog-post" v-if="post">
            <h1>{{ post.Name }}</h1>
            <div v-html="post.Content"></div>
            <Navigation mode="simple"></Navigation>
        </div>
    </template>
</template>

<script>
    import axios from 'axios';
    import Navigation from '../navigation/navigation.vue'

    const arrayRand = function (array) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    }

    export default {
        components: {
            'Navigation': Navigation,
        },
        data() {
            return {
                post: null
            }
        },
        async created() {
            const response = await axios.get('/cloud');

            this.post = arrayRand(response.data).reduce((acc, value) => {
                let size = parseInt((100 + value.Count + (100 * value.Count)) / 5);
                if (size > 200) {
                    size = 200;
                }
                acc.Content += `<span class="cloud-element" style="font-size: ${size}%"><a href="/#/tags/${value.Tag}">${value.Tag}</a>&nbsp;</span>`

                return acc
            }, {Content: ""})
        }
    }
</script>

<style>
    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
        margin-top: 60px;
    }
</style>
